@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body{
  font-family: 'Poppins', sans-serif;
}

.form-control::placeholder {
  color: silver;
  opacity: 1;
}

.light-bg-navbar {
  background-color: rgb( 255 255 255 / 95%);
}

.dark-bg-navbar {
  background-color: rgb( 14 16 35 / 95%);
}

.nav-footer-link {
  color: #9F9F9F;
  text-decoration: none;
}

.navbar-toggler:focus{
  box-shadow: none !important;
}


@font-face {
  font-family: 'icon-coldbox';
  src:  url('../fonts/icon-coldbox.eot?rkdy5y');
  src:  url('../fonts/icon-coldbox.eot?rkdy5y#iefix') format('embedded-opentype'),
    url('../fonts/icon-coldbox.ttf?rkdy5y') format('truetype'),
    url('../fonts/icon-coldbox.woff?rkdy5y') format('woff'),
    url('../fonts/icon-coldbox.svg?rkdy5y#icon-coldbox') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon-coldbox' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-down:before {
  content: "\e900";
}
.icon-chevron-left:before {
  content: "\e901";
}
.icon-chevron-right:before {
  content: "\e902";
}
.icon-chevron-up:before {
  content: "\e903";
}
.icon-copy:before {
  content: "\e904";
}
.icon-facebook:before {
  content: "\e905";
}
.icon-git:before {
  content: "\e906";
}
.icon-group:before {
  content: "\e907";
}
.icon-menu:before {
  content: "\e908";
}
.icon-moon:before {
  content: "\e909";
}
.icon-patreon:before {
  content: "\e90a";
}
.icon-sun:before {
  content: "\e90b";
}
.icon-vimeo:before {
  content: "\e90c";
}
.icon-x:before {
  content: "\e90d";
}
.icon-youtube:before {
  content: "\e90e";
}

.mt-negative-80{
  margin-top: -80px;
}

.padding-vertical{
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.padding-xxsmall{
  padding: 0.75rem;
}

.client-logo-marquee-wrapper{
  height: 3.4375rem;
  grid-column-gap: 1.5rem;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.client-logo-marquee-content{
  min-width: 100%;
  grid-column-gap: 3rem;
  flex: none;
  justify-content: space-around;
  display: flex;
}

.logo-icon-slider{
  width: 100%;
  height: 100%;
  max-height: 55px;
  max-width: 130px;
}

.nav-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: space-between;
}

#courses-logo {
  text-decoration: none;
  &:hover img {
    content: url("https://www.ortussolutions.com/__media/coldbox-site/2023/university/ortus-courses.svg");
  }
}

#cfcasts-logo {
  text-decoration: none;
  &:hover img {
    content: url("https://www.ortussolutions.com/__media/coldbox-site/2023/university/cfcasts.svg");
  }
}

#books-logo {
  text-decoration: none;
  &:hover img {
    content: url("https://www.ortussolutions.com/__media/coldbox-site/2023/university/ortus-books.svg");
  }
}

#docs-logo {
  text-decoration: none;
  &:hover img {
    content: url("https://www.ortussolutions.com/__media/coldbox-site/2023/university/ortus-docs.svg");
  }
}


.social-media-icon-size{
  font-size: 20px;
}

.theme-control-size {
  width: 66px !important;
  height: 40px; 
}

.top-logo {
  top: 42%;
}

.img-size {
  width: 60%;
}

.main-title-size {
  font-size: calc( 1rem + 2vw );
}

@media (min-width: 375px) {
  .top-logo {
    top: 40%;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

  .top-logo {
    top: 50%;
  }

  .img-size {
    width: 40%;
  }

  .main-title-size {
    font-size: 1.1rem;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 767px) {
  .top-logo {
    top: 45%;
  }
  .img-size {
    width: 42%;
  }
  .main-title-size {
    font-size: 1rem;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 843px) {
  .top-logo {
    top: 38%;
  }
  .img-size {
    width: 60%;
  }
  .main-title-size {
    font-size: 1.6rem;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .top-logo {
    top: 42%;
  }
  
  .img-size {
    width: 70%;
  }

  .main-title-size {
    font-size: 1.8rem;
  }

  .social-media-icon-size{
    font-size: 16px;
  }
  
  .theme-control-size {
    width: 36px !important;
    height: 20px; 
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .top-logo {
    top: 43%;
  }
  .img-size {
    width: 75%;
  }
  .main-title-size {
    font-size: 2rem;
  }
}